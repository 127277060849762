import React from 'react';
import { useTranslation } from 'react-i18next';

const Reconstruction220kvVanovka = () => {
    const { t } = useTranslation();
    return (
        <section className='in-progress-information'>
            <div className='section__title'>
                <div className='container'>
                    <h3 className='section__title-text'>{t("Реконструкция ПС 220 кВ Ванновка")}</h3>
                </div>
            </div>
            <div className='container'>
                <div className='in-progress-information__row'>
                    <div className='in-progress-information__item'>
                        <img className='in-progress-information__item-img' src='/assets/images/in-progress/reconstruction-220kv-vanovka/01.webp' alt='' />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Reconstruction220kvVanovka;