import React from 'react';
import './History.scss';
import { useTranslation } from 'react-i18next';

const History = () => {
    const { t } = useTranslation();
    return (
        <section className="history">
            <div className='section__title'>
                <div className='container'>
                    <h3 className='section__title-text'>{t("История")}</h3>
                </div>
            </div>
            <div className='container'>
                <div className='history__text'>
                    <p>
                        {t("ТОО «VICTORY KAZ ENERGY» — стремительно развивающаяся строительно-монтажная компания в Алматы. Компания основана в 2006 году как товарищество с ограниченной ответственностью «Вифлеем». Деятельность организации началась с выполнения небольших объемов строительно-монтажных работ, а сплоченный коллектив, который профессионально и качественно выполнял поставленные задачи, вывел компанию на новый уровень.")}
                    </p>
                    <p>
                        <br/>{t("Постепенно набираясь опыта, совершенствуя методы производства работ, расширяя сферы деятельности 2022 года компания «Вифлеем» была реаргонизовано в товарищество с ограниченной ответственностью «VICTORY KAZ ENERGY». Основным направлением компании является работы и услуги в области энергетики, а так же строительно-монтажные, земляные, отделочные и работы по благоустройству территорий. Наше предприятие успешно существует на Казахстанском рынке уже более 14 лет, имея в своем штате квалифицированных специалистов.")}
                    </p>
                    <p>
                        <br/>{t("Сейчас ТОО «VICTORY KAZ ENERGY» выполняет строительно-монтажные работы всех видов на промышленных и гражданских объектах не только Алматинской области, но и в Республики Казахстан. На сегодняшний день основными задачами компании является производство строительно-монтажных работ в целях выполнения установленного плана ввода в действие строящихся объектов, повышение производительности труда и улучшение качества выполняемых работ путем непрерывного совершенствования строительного производства, применения новых технологий и наращивание объемов выполняемых работ. А также улучшение условий работы и быта специалистов. Безупречное качество работы, выполняемая с первого дня существования компании, говорит, что мы гарантируем успешное сотрудничество для новых и постоянных клиентов.")}
                    </p>
                </div>
            </div>
		</section>
    );
}

export default History;