import React from 'react';
import TypesOfServices from './Types-of-services/Types-of-services';

const Services = () => {
    return (
        <div className="services">
            <TypesOfServices/>
		</div>
    );
}

export default Services;