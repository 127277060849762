import React from 'react';
import History from './History/History';

const About = () => {
    return (
        <div className="about">
            <History/>
		</div>
    );
}

export default About;